import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Paper,
  TableBody,
  // Button,
} from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import EditCustomer from "./EditCustomer";

export default function CustomerList(props) {
  const { customers, selected, setSelected } = props;
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [openEditCustomer, setOpenEditCustomer] = useState(false);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" sx={{ minWidth: 100 }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Customer Number</b>
              </TableCell>
              <TableCell>
                <b>Customer Name</b>
              </TableCell>
              <TableCell>
                <b>Address</b>
              </TableCell>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell>
                <b>Phone</b>
              </TableCell>
              {/* <TableCell>
              <b>Contact</b>
            </TableCell> */}
              {/* <TableCell>
              <b>Action</b>
            </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {customers?.length > 0 ? (
              customers?.map((customer, i) => {
                return (
                  <TableRow
                    key={i}
                    hover
                    selected={selected === i}
                    onDoubleClick={() => {
                      setOpenEditCustomer(true);
                    }}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSelectedCustomer(customers?.[i]);
                      setSelected(i);
                    }}
                  >
                    <TableCell>{customer?.customerNumber}</TableCell>
                    <TableCell>{customer?.customerName}</TableCell>
                    <TableCell>{customer?.address}</TableCell>
                    <TableCell>{customer?.email}</TableCell>
                    <TableCell>{customer?.phone}</TableCell>
                    {/* <TableCell>
                    <Button onClick={() => {}}>Edit</Button>
                  </TableCell> */}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No Records to Display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertDialog
        // customControls={false}
        open={openEditCustomer}
        handleClose={() => {
          setOpenEditCustomer(false);
        }}
        dialogContent={
          <EditCustomer
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
          />
        }
        dialogTitle="Edit Customer"
      />
    </div>
  );
}
