import React from "react";
import Box from "@mui/material/Box";
import { FormLabel, Grid, Paper } from "@mui/material";

export default function Tab(props) {
  const { title } = props;
  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper style={{ padding: 20, margin: 2, height: 1000 }}>
            <FormLabel sx={{ fontSize: "2rem" }}>{title}</FormLabel>
            <div>{props?.children}</div>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
