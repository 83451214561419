import * as React from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import {
  Autocomplete,
  Grid,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import EditOrder from "Components/Orders/EditOrder";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import EditDriver from "Components/Drivers/EditDriver";
import EditCustomer from "Components/Customers/EditCustomer";

import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { orderSearch } from "API/Orders";
import { VishcorpContext } from "Context";

// const { afterToday } = DateRangePicker;

const SearchResultItem = ({
  key,
  id,
  idLabel,
  value,
  valueLabel,
  handleClick,
}) => {
  return (
    <ListItemButton key={key} onClick={handleClick}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography fontSize={10} gutterBottom>
            {idLabel}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            {id}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize={10} gutterBottom>
            {valueLabel}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            {value}
          </Typography>
        </Grid>
      </Grid>
    </ListItemButton>
  );
};

export default function SearchBar(props) {
  const { orgSettings } = React.useContext(VishcorpContext);
  const [searchInput, setSearchInput] = React.useState(undefined);
  const [searchResults, setSearchResults] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState(undefined);
  const [dialogTitle, setDialogTitle] = React.useState(undefined);

  const [dateRange, setDateRange] = React.useState([]);
  const [autoFocus, setAutoFocus] = React.useState(false);

  const debouncedSearch = React.useRef(
    debounce(async (value, pDateRange, organizationTablePrefix) => {
      // const { value } = e?.target;
      // if (!value) {
      //   return;
      // }
      // Fetch Here for Better results
      console.log(value, pDateRange, organizationTablePrefix);

      orderSearch(value, pDateRange, organizationTablePrefix)
        .then((res) => {
          setSearchResults(res?.response);
          setAutoFocus(true);
        })
        .catch((err) => console.error(err));
    }, 300)
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  React.useEffect(() => {
    if (!searchInput || searchInput?.length <= 0) {
      setSearchResults([]);
    }
  }, [searchInput]);

  const handleChange = (e) => {
    setSearchInput(e?.target?.value);
    debouncedSearch(
      e?.target?.value,
      dateRange,
      orgSettings?.organizationTablePrefix
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DateRangePicker
          size="lg"
          // disabledDate={afterToday()}
          value={dateRange}
          onChange={setDateRange}
        />
      </div>
      <Autocomplete
        options={searchResults?.sort((a, b) => a?.group < b?.group)}
        filterOptions={(x) => x}
        inputValue={searchInput ?? ""}
        onInputChange={handleChange}
        openOnFocus
        groupBy={(option) => option?.group}
        getOptionLabel={(option) => option?.metric}
        renderOption={(props, option) => {
          switch (option?.metric) {
            case "driverName":
              return (
                <SearchResultItem
                  idLabel={"Driver Number"}
                  valueLabel={"Driver Name"}
                  key={option?.data?.driverNumber}
                  value={option?.data?.driverName}
                  id={option?.data?.driverNumber}
                  handleClick={() => {
                    setDialogTitle("Driver");
                    setDialogContent(
                      <EditDriver selectedDriver={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "driverNumber":
              return (
                <SearchResultItem
                  idLabel={"Driver Number"}
                  valueLabel={"Driver Name"}
                  key={option?.data?.driverNumber}
                  value={option?.data?.driverName}
                  id={option?.data?.driverNumber}
                  handleClick={() => {
                    setDialogTitle("Driver");
                    setDialogContent(
                      <EditDriver selectedDriver={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "driverID":
              return (
                <SearchResultItem
                  idLabel={"Driver Number"}
                  valueLabel={"Driver ID"}
                  key={option?.data?.driverID}
                  value={option?.data?.driverID}
                  id={option?.data?.driverNumber}
                  handleClick={() => {
                    setDialogTitle("Driver");
                    setDialogContent(
                      <EditDriver selectedDriver={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "customerName":
              return (
                <SearchResultItem
                  idLabel={"Customer Number"}
                  valueLabel={"Customer Name"}
                  key={option?.data?.customerNumber}
                  value={option?.data?.customerName}
                  id={option?.data?.customerNumber}
                  handleClick={() => {
                    setDialogTitle("Customer");
                    setDialogContent(
                      <EditCustomer selectedCustomer={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "customerNumber":
              return (
                <SearchResultItem
                  idLabel={"Customer Number"}
                  valueLabel={"Customer Name"}
                  key={option?.data?.customerNumber}
                  value={option?.data?.customerName}
                  id={option?.data?.customerNumber}
                  handleClick={() => {
                    setDialogTitle("Customer");
                    setDialogContent(
                      <EditCustomer selectedCustomer={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "orderNumber":
              return (
                <SearchResultItem
                  idLabel={"Order Number"}
                  valueLabel={"Order Reference"}
                  key={option?.data?.orderNumber}
                  value={option?.data?.orderReference}
                  id={option?.data?.orderNumber}
                  handleClick={() => {
                    setDialogTitle("Order");
                    setDialogContent(
                      <EditOrder selectedOrder={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "customerID":
              return (
                <SearchResultItem
                  idLabel={"Customer Number"}
                  valueLabel={"Customer ID"}
                  key={option?.data?.customerNumber}
                  value={option?.data?.customerID}
                  id={option?.data?.customerNumber}
                  handleClick={() => {
                    setDialogTitle("Customer");
                    setDialogContent(
                      <EditCustomer selectedCustomer={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "orderReference":
              return (
                <SearchResultItem
                  idLabel={"Order ID"}
                  valueLabel={"Order Reference"}
                  key={option?.data?.orderNumber}
                  value={option?.data?.orderReference}
                  id={option?.data?.orderID}
                  handleClick={() => {
                    setDialogTitle("Order");
                    setDialogContent(
                      <EditOrder selectedOrder={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "orderID":
              return (
                <SearchResultItem
                  idLabel={"Order ID"}
                  valueLabel={"Order ID"}
                  key={option?.data?.orderNumber}
                  value={option?.data?.orderID}
                  id={option?.data?.orderID}
                  handleClick={() => {
                    setDialogTitle("Order");
                    setDialogContent(
                      <EditOrder selectedOrder={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "pickupPostCode":
              return (
                <SearchResultItem
                  idLabel={"Order ID"}
                  valueLabel={"Pick Up Post Code"}
                  key={option?.data?.orderID}
                  value={option?.data?.pickupPostCode}
                  id={option?.data?.orderID}
                  handleClick={() => {
                    setDialogTitle("Order");
                    setDialogContent(
                      <EditOrder selectedOrder={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "deliveryPostCode":
              return (
                <SearchResultItem
                  idLabel={"Order ID"}
                  valueLabel={"Delivery Post Code"}
                  key={option?.data?.orderID}
                  value={option?.data?.deliveryPostCode}
                  id={option?.data?.orderID}
                  handleClick={() => {
                    setDialogTitle("Order");
                    setDialogContent(
                      <EditOrder selectedOrder={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "pickupAddress":
              return (
                <SearchResultItem
                  idLabel={"Order ID"}
                  valueLabel={"Pickup Address"}
                  key={option?.data?.orderID}
                  value={option?.data?.pickupAddress}
                  id={option?.data?.orderID}
                  handleClick={() => {
                    setDialogTitle("Order");
                    setDialogContent(
                      <EditOrder selectedOrder={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "deliveryAddress":
              return (
                <SearchResultItem
                  idLabel={"Order ID"}
                  valueLabel={"Delivery Address"}
                  key={option?.data?.orderID}
                  value={option?.data?.deliveryAddress}
                  id={option?.data?.orderID}
                  handleClick={() => {
                    setDialogTitle("Order");
                    setDialogContent(
                      <EditOrder selectedOrder={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            case "dateRange":
              return (
                <SearchResultItem
                  idLabel={"Order TimeStamp"}
                  valueLabel={"Delivery Address"}
                  key={option?.data?.orderID}
                  value={option?.data?.deliveryAddress}
                  id={String(option?.data?.orderTimestamp)?.split("T")?.[0]}
                  handleClick={() => {
                    setDialogTitle("Order");
                    setDialogContent(
                      <EditOrder selectedOrder={option?.data} />
                    );
                    setOpenDialog(true);
                  }}
                />
              );
            default:
              return (
                <ListItemButton>
                  <ListItemText></ListItemText>
                </ListItemButton>
              );
          }
        }}
        placeholder="Search"
        renderInput={(params) => (
          <Paper
            component="form"
            sx={{
              p: "1px 1px",
              display: "flex",
              alignItems: "center",
              marginLeft: 1,
              width: "35vh",
            }}
          >
            <TextField
              {...params}
              sx={{ ml: 1, flex: 1 }}
              autoFocus={autoFocus}
            />
            <IconButton
              type="button"
              aria-label="search"
              onClick={() => {
                handleChange();
              }}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        )}
      />
      <AlertDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        dialogContent={dialogContent ?? <></>}
        dialogTitle={dialogTitle}
        customControls={false}
      />
    </div>
  );
}
