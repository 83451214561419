import React, { useContext, useState } from "react";
import Tab from "Components/Tabs/Tab";
import DriverList from "./DriverList";
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { primaryButtonColor } from "constants";
import { primaryButtonHoverColor } from "constants";
import AddDriver from "./AddDriver";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { VishcorpContext } from "Context";
import { deleteDriver } from "API/Drivers";

export default function Drivers(props) {
  const { drivers, refreshDrivers, orgSettings } = useContext(VishcorpContext);
  const [showInactiveDrivers, setShowInactiveDrivers] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [showAlert, setShowAlert] = useState(false);

  return (
    <Tab title="Drivers">
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignContent: "right",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={showInactiveDrivers}
              onChange={() => {
                setShowInactiveDrivers((pre) => !pre);
              }}
            />
          }
          label="Show Inactive Drivers"
        />
        <Button
          variant="contained"
          sx={{
            marginLeft: 2,
            borderRadius: 0,
            backgroundColor: primaryButtonColor,
            "&:hover": {
              backgroundColor: primaryButtonHoverColor,
            },
          }}
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          Add Driver
        </Button>
        <Button
          variant="contained"
          sx={{
            marginLeft: 2,
            borderRadius: 0,
            backgroundColor: primaryButtonColor,
            "&:hover": {
              backgroundColor: primaryButtonHoverColor,
            },
          }}
          onClick={() => {
            if (drivers?.[selected] !== undefined) {
              setShowAlert(true);
            }
            // setDialogOpen(true);
          }}
        >
          Delete Driver
        </Button>
      </div>
      <br />
      <DriverList
        drivers={drivers}
        selected={selected}
        setSelected={setSelected}
        deleteDriver
      />
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <AddDriver
          handleClose={() => {
            refreshDrivers();
            setDialogOpen(false);
          }}
        />
      </Dialog>
      <AlertDialog
        open={showAlert}
        handleClose={() => {
          setShowAlert(false);
        }}
        handleConfirm={async () => {
          await deleteDriver(
            drivers?.[selected]?.driverID,
            orgSettings?.organizationTablePrefix
          );
          setShowAlert(false);
          refreshDrivers();
        }}
        dialogTitle=""
        dialogContent={
          <FormLabel>
            <b>
              Confirm Delete Driver: {drivers?.[selected]?.driverNumber} -{" "}
              {drivers?.[selected]?.driverName}?
            </b>
          </FormLabel>
        }
      />
    </Tab>
  );
}
