import {
    Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import AddressInput from "Templates/UI/Input/AddressInput";
import Email from "Templates/UI/Input/Email";
import Phone from "Templates/UI/Input/Phone";

export default function AddCostCenter(props) {
  const { onAddCostCenter, handleClose, openAlertMessage, setOpenAlertMessage, errorMessage } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [costCenterDetails, setCostCenterDetails] = useState({
    costCenterName: "",
    password: "",
    address: "",
    city: "",
    postalCode: "",
    province: "",
    country: "",
    phone: "",
    email: "",
    fax: "",
    notes: "",
    contact: "",
  });
  const onCostCenterDetailChange = (e, name) => {
    setCostCenterDetails((pre) => {
      return { ...pre, [name]: e };
    });
  };
  return (
    <div>
      <TextField
        error={errors?.["costCenterName"] && true}
        helperText={errors?.["costCenterName"]?.message}
        {...register("costCenterName", {
          required: "Required*",
        })}
        style={{ marginTop: 10 }}
        fullWidth
        size="small"
        label="Cost Center Name"
        value={costCenterDetails?.costCenterName}
        onChange={(e) =>
          onCostCenterDetailChange(e?.target?.value, "costCenterName")
        }
      />
      <TextField
        error={errors?.["password"] && true}
        helperText={errors?.["password"]?.message}
        {...register("password", {
          required: "Required*",
        })}
        style={{ marginTop: 10 }}
        fullWidth
        type="password"
        size="small"
        label="Password"
        value={costCenterDetails?.password}
        onChange={(e) => onCostCenterDetailChange(e?.target?.value, "password")}
      />
      <AddressInput
        register={register}
        errors={errors}
        required={true}
        style={{ marginTop: 10 }}
        value={costCenterDetails?.address}
        setValue={(e) => {
          onCostCenterDetailChange(e, "address");
          onCostCenterDetailChange(e?.city ?? "", "city");
          setValue("city", e?.city);
          onCostCenterDetailChange(e?.postCode ?? "", "postalCode");
          setValue("postalCode", e?.postCode);
          onCostCenterDetailChange(e?.state ?? "", "province");
          setValue("province", e?.state);
          onCostCenterDetailChange(e?.country ?? "", "country");
          setValue("country", e?.country);
        }}
        label={"Address"}
      />
      <TextField
        error={errors?.["city"] && true}
        helperText={errors?.["city"]?.message}
        {...register("city", {
          required: "Required*",
        })}
        fullWidth
        size="small"
        label="City"
        value={costCenterDetails?.city}
        onChange={(e) => onCostCenterDetailChange(e?.target?.value, "city")}
      />
      <TextField
        error={errors?.["postalCode"] && true}
        helperText={errors?.["postalCode"]?.message}
        {...register("postalCode", {
          required: "Required*",
        })}
        style={{ marginTop: 10 }}
        fullWidth
        size="small"
        label="Postal Code"
        value={costCenterDetails?.postalCode}
        onChange={(e) =>
          onCostCenterDetailChange(e?.target?.value, "postalCode")
        }
      />
      <FormControl
        fullWidth
        style={{ width: "100%", marginTop: 10 }}
        size="small"
      >
        <InputLabel>Province</InputLabel>
        <Select
          {...register("province", {
            required: "Required*",
          })}
          error={errors?.province && true}
          label="Province"
          size="small"
          value={costCenterDetails?.province}
          onChange={(e) => {
            onCostCenterDetailChange(e?.target?.value, "province");
          }}
        >
          <MenuItem value={"Victoria"}>Vic</MenuItem>
          <MenuItem value={"South Australia"}>SA</MenuItem>
          <MenuItem value={"Australian Capital Territory"}>ACT</MenuItem>
          <MenuItem value={"Northern Territory"}>NT</MenuItem>
          <MenuItem value={"New South Wales"}>NT</MenuItem>
          <MenuItem value={"Queensland"}>Qld</MenuItem>
          <MenuItem value={"Tasmania"}>Tas</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        fullWidth
        style={{ width: "100%", marginTop: 10 }}
        size="small"
      >
        <InputLabel>Country</InputLabel>
        <Select
          {...register("country", {
            required: "Required*",
          })}
          error={errors?.country && true}
          label={"Country"}
          fullWidth
          value={costCenterDetails?.country}
          onChange={(e) => {
            onCostCenterDetailChange(e?.target?.value, "country");
          }}
        >
          <MenuItem value={"Australia"}>Australia</MenuItem>
        </Select>
      </FormControl>
      <Phone
        errors={errors}
        required={true}
        register={register}
        style={{ marginTop: 10 }}
        value={costCenterDetails?.phone}
        setValue={(e) => {
          onCostCenterDetailChange(e, "phone");
        }}
        label={"Phone"}
      />
      <TextField
        error={errors?.["fax"] && true}
        helperText={errors?.["fax"]?.message}
        {...register("fax", {
          required: "Required*",
        })}
        style={{ marginTop: 10 }}
        fullWidth
        size="small"
        label="Fax"
        value={costCenterDetails?.fax}
        onChange={(e) => onCostCenterDetailChange(e?.target?.value, "fax")}
      />
      <Email
        required={true}
        register={register}
        errors={errors}
        style={{ marginTop: 10 }}
        value={costCenterDetails?.email}
        setValue={(e) => onCostCenterDetailChange(e, "email")}
        label={"Email"}
      />
      <TextField
        error={errors?.["contact"] && true}
        helperText={errors?.["contact"]?.message}
        {...register("contact", {
          required: "Required*",
        })}
        style={{ marginTop: 10 }}
        fullWidth
        size="small"
        label="Contact"
        value={costCenterDetails?.contact}
        onChange={(e) => onCostCenterDetailChange(e?.target?.value, "contact")}
      />
      <TextField
        error={errors?.["notes"] && true}
        helperText={errors?.["notes"]?.message}
        {...register("notes", {
          required: "Required*",
        })}
        style={{ marginTop: 10 }}
        fullWidth
        multiline
        size="small"
        label="Notes"
        InputProps={{
          inputComponent: TextareaAutosize,
          rows: 3,
          maxRows: 5,
        }}
        value={costCenterDetails?.notes}
        onChange={(e) => onCostCenterDetailChange(e?.target?.value, "notes")}
      />
      <br />
      <div>
        <Button
          sx={{
            marginTop: 5,
            marginRight: 5,
            borderRadius: 0,
          }}
          variant="contained"
          onClick={handleSubmit(() => {
            onAddCostCenter(costCenterDetails);
          })}
        >
          Add Cost Center
        </Button>
        <Button
          sx={{
            marginTop: 5,
            borderRadius: 0,
          }}
          color="error"
          variant="contained"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </div>
      <Snackbar
        open={openAlertMessage}
        autoHideDuration={6000}
        onClose={() => {
          setOpenAlertMessage(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenAlertMessage(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {`Error Code: ${errorMessage?.code}. ${errorMessage?.message}`}
        </Alert>
      </Snackbar>
    </div>
  );
}
