import { Autocomplete, TextField } from "@mui/material";
import { addressSearch, GAutoComplete } from "Components/GAutoComplete";
import { useState } from "react";

export default function AddressInput(props) {
  const {
    // value,
    setValue,
    label,
    required,
    register,
    errors,
    style,
  } = props;
  const [options, setOptions] = useState(undefined);

  return (
    <Autocomplete
      size="small"
      label={label}
      variant="outlined"
      options={options ?? []}
      onKeyDown={async (e, val) => {
        switch (e?.key) {
          case "Enter":
            const pAddressDetails = await addressSearch(e, val);
            if (!pAddressDetails) {
              return;
            }
            let pValue = JSON.parse(JSON.stringify({}));
            // let pValue = JSON.parse(JSON.stringify(value ?? {}));
            pValue = {
              ...(pValue ?? {}),
              country: pAddressDetails?.address_components?.find((e) =>
                e?.types?.includes("country")
              )?.long_name,
            };
            pValue = {
              ...(pValue ?? {}),
              state: pAddressDetails?.address_components?.find((e) =>
                e?.types?.includes("administrative_area_level_1")
              )?.long_name,
            };
            pValue = {
              ...(pValue ?? {}),
              postCode: pAddressDetails?.address_components?.find((e) =>
                e?.types?.includes("postal_code")
              )?.long_name,
            };
            pValue = {
              ...(pValue ?? {}),
              latitude: pAddressDetails?.geometry.location.lat(),
            };
            pValue = {
              ...(pValue ?? {}),
              longitude: pAddressDetails?.geometry.location.lng(),
            };
            pValue = {
              ...(pValue ?? {}),
              city: pAddressDetails?.address_components?.find((e) =>
                e?.types?.includes("locality")
              )?.long_name,
            };
            pValue = {
              ...(pValue ?? {}),
              address: pAddressDetails?.formatted_address,
            };
            setValue(pValue);
            break;
          default:
            break;
        }
      }}
      onChange={async (e, val) => {
        const pAddressDetails = await addressSearch(e, val);
        if (!pAddressDetails) {
          return;
        }
        let pValue = JSON.parse(JSON.stringify({}));
        // let pValue = JSON.parse(JSON.stringify(value ?? {}));
        pValue = {
          ...(pValue ?? {}),
          country: pAddressDetails?.address_components?.find((e) =>
            e?.types?.includes("country")
          )?.long_name,
        };
        pValue = {
          ...(pValue ?? {}),
          state: pAddressDetails?.address_components?.find((e) =>
            e?.types?.includes("administrative_area_level_1")
          )?.long_name,
        };
        pValue = {
          ...(pValue ?? {}),
          postCode: pAddressDetails?.address_components?.find((e) =>
            e?.types?.includes("postal_code")
          )?.long_name,
        };
        pValue = {
          ...(pValue ?? {}),
          latitude: pAddressDetails?.geometry.location.lat(),
        };
        pValue = {
          ...(pValue ?? {}),
          longitude: pAddressDetails?.geometry.location.lng(),
        };
        pValue = {
          ...(pValue ?? {}),
          city: pAddressDetails?.address_components?.find((e) =>
            e?.types?.includes("locality")
          )?.long_name,
        };
        pValue = {
          ...(pValue ?? {}),
          address: pAddressDetails?.formatted_address,
        };
        setValue(pValue);
      }}
      style={{ ...(style ?? {}), width: "100%", marginBottom: 15 }}
      filterSelectedOptions
      autoComplete
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          error={errors?.[label] && required}
          helperText={errors?.[label]?.message}
          {...params}
          label={label}
          fullWidth
          // defaultValue={defaultValue ?? ""}
          size="small"
          {...(register &&
            register(label, {
              required: required ? "Required*" : false,
            }))}
          onChange={(e) => {
            GAutoComplete(e?.target?.value)
              .then((res) => {
                // console.log("Suggestions===>", res);
                setOptions(
                  res?.map((e) => ({ label: e?.description, data: e }))
                );
              })
              .catch(console.error);
          }}
          // value={value}
        />
      )}
    />
  );
}
