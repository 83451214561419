import * as React from "react";
import { Buffer } from "buffer";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { primaryAppBarColor } from "../../constants";
import SearchBar from "Components/Search/SearchBar";
import AccountOptions from "./AccountOptions";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SearchIcon from "@mui/icons-material/Search";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import SettingsIcon from "@mui/icons-material/Settings";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import {
  // IconButton,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { VishcorpContext } from "Context";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function MenuDrawer(props) {
  let navigate = useNavigate();
  const { orgSettings, userInfo, organizationLogo } =
    React.useContext(VishcorpContext);
  const generalSettings = orgSettings?.generalSettings;
  const [dispatcherDetails, setDispatcherDetails] = React.useState(undefined);
  const [selectedOption, setSelectedOption] = React.useState("Today's Orders");

  const [parsedOrganizationLogo, setParseOrganizationLogo] =
    React.useState(undefined);

  const handleSelection = (event, newAlignment) => {
    setSelectedOption(newAlignment);
  };

  React.useEffect(() => {
    if (!organizationLogo?.data) return;
    setParseOrganizationLogo(
      JSON.parse(new Buffer.from(organizationLogo?.data).toString())?.data
    );
  }, [organizationLogo]);

  React.useEffect(() => {
    if (!userInfo) {
      return;
    }
    if (userInfo?.attributes?.["custom:isDispatcher"]) {
      setDispatcherDetails(
        orgSettings?.dispatchers?.find(
          (e) =>
            e?.dispatcherID === userInfo?.attributes?.["custom:dispatcherID"]
        )
      );
    }
  }, [userInfo, orgSettings]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{
          background: primaryAppBarColor,
        }}
      >
        <Toolbar>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="h6"
              noWrap
              sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
            >
              <Link
                component="button"
                href="#"
                onClick={() => {
                  navigate("/");
                }}
                color="inherit"
                underline="none"
              >
                {organizationLogo?.data !== undefined &&
                parsedOrganizationLogo !== undefined ? (
                  <img
                    src={`data:image/png;base64, ${parsedOrganizationLogo}`}
                    alt="No Logo"
                    style={{ height: "30px", marginRight: 10 }}
                  />
                ) : (
                  ""
                )}
                {generalSettings?.organizationDisplayName ??
                  orgSettings?.organizationName}
              </Link>
            </Typography>
            <div style={{ marginLeft: 10 }}>
              <ToggleButtonGroup
                value={selectedOption}
                exclusive={true}
                onChange={handleSelection}
              >
                <ToggleButton
                  value="Today's Orders"
                  onClick={() => {
                    navigate("/Orders");
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    <AccessTimeIcon />
                    <div style={{ fontSize: 12 }}>
                      Today's
                      <br />
                      Orders
                    </div>
                  </div>
                </ToggleButton>
                {(dispatcherDetails?.accessControl?.completedOrders ||
                  dispatcherDetails === undefined) && (
                  <ToggleButton
                    value="Completed Orders"
                    onClick={() => {
                      navigate("/CompletedOrders");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <SearchIcon />
                      <div style={{ fontSize: 12 }}>
                        Completed
                        <br />
                        Orders
                      </div>
                    </div>
                  </ToggleButton>
                )}
                {(dispatcherDetails?.accessControl?.invoices ||
                  dispatcherDetails === undefined) && (
                  <ToggleButton
                    value="Invoices"
                    onClick={() => {
                      navigate("/Invoices");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <AssignmentIcon />
                      <div style={{ fontSize: 15 }}>Invoices</div>
                    </div>
                  </ToggleButton>
                )}
                {(dispatcherDetails?.accessControl?.customers ||
                  dispatcherDetails === undefined) && (
                  <ToggleButton
                    value="Customers"
                    onClick={() => {
                      navigate("/Customers");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <PeopleAltIcon />
                      <div style={{ fontSize: 15 }}>Customers</div>
                    </div>
                  </ToggleButton>
                )}
                {(dispatcherDetails?.accessControl?.drivers ||
                  dispatcherDetails === undefined) && (
                  <ToggleButton
                    value="Drivers"
                    onClick={() => {
                      navigate("/Drivers");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <DriveEtaIcon />
                      <div style={{ fontSize: 15 }}>Drivers</div>
                    </div>
                  </ToggleButton>
                )}
                {(dispatcherDetails?.accessControl?.settings ||
                  dispatcherDetails === undefined) && (
                  <ToggleButton
                    value="Settings"
                    onClick={() => {
                      navigate("/Settings");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <SettingsIcon />
                      <div style={{ fontSize: 15 }}>Settings</div>
                    </div>
                  </ToggleButton>
                )}
                <ToggleButton value="Discover">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    <TravelExploreIcon />
                    <div style={{ fontSize: 15 }}>Discover</div>
                  </div>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <SearchBar />
          </div>
          <AccountOptions
            color="inherit"
            onSignOut={async () => {
              try {
                await Auth.signOut();
              } catch (error) {
                console.error(error);
              }
            }}
          />
        </Toolbar>
      </AppBar>
      {/* <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {Object.keys(menuItems).map((key, index) => (
            <ListItem key={key} disablePadding sx={{ display: "block" }}>
              <Tooltip title={menuItems[key]?.label}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => {
                    navigate(menuItems[key]?.link);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {menuItems[key]?.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={menuItems[key]?.label}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer> */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props?.children}
      </Box>
    </Box>
  );
}
