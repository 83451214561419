import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AttachmentsTable from "./AttachmentsTable";
import { getOrderAttachments, getOrderDeliveryAttachments } from "API";
import { VishcorpContext } from "Context";

export default function OrderAttachments(props) {
  const { orgSettings } = React.useContext(VishcorpContext);
  const { orderID } = props;
  const [orderAttachments, setOrderAttachments] = React.useState(undefined);
  const [deliveryAttachments, setDeliveryAttachments] =
    React.useState(undefined);
  //   const [orderDeliveryAttachments, setOrderDeliveryAttachments] =
  //     React.useState(undefined);
  const [value, setValue] = React.useState("1");

  React.useEffect(() => {
    if (!orderID) return;
    getOrderAttachments(orderID, orgSettings?.organizationTablePrefix)
      .then(setOrderAttachments)
      .catch(console.error);
    getOrderDeliveryAttachments(orderID, orgSettings?.organizationTablePrefix)
      .then(setDeliveryAttachments)
      .catch(console.error);
  }, [orderID, orgSettings]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Order Attachments" value="1" />
            <Tab label="Delivery Attachments" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginLeft: -2, marginRight: -2 }}>
          <AttachmentsTable
            attachments={orderAttachments}
            orderID={orderID}
            type="order"
          />
        </TabPanel>
        <TabPanel value="2" sx={{ marginLeft: -2, marginRight: -2 }}>
          <AttachmentsTable
            attachments={deliveryAttachments}
            orderID={orderID}
            type="delivery"
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
