import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Paper,
  TableBody,
  Box,
  Grid,
} from "@mui/material";
import EditDriver from "./EditDriver";
import AlertDialog from "Components/AlertDialog/AlertDialog";

export default function DriverList(props) {
  const { drivers, selected, setSelected } = props;
  const [openEditDriver, setOpenEditDriver] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(undefined);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" sx={{ minWidth: 100 }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Driver ID</b>
              </TableCell>
              <TableCell>
                <b>Name</b>
              </TableCell>
              {/* <TableCell>
              <b>Email</b>
            </TableCell> */}
              <TableCell>
                <b>Phone</b>
              </TableCell>
              {/* <TableCell>
              <b>Provider</b>
            </TableCell> */}
              {/* <TableCell>
              <b>Action</b>
            </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {drivers?.length > 0 ? (
              drivers?.map((driver, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    hover
                    onDoubleClick={() => {
                      setOpenEditDriver(true);
                    }}
                    selected={selected === i}
                    onClick={() => {
                      setSelected(i);
                      setSelectedDriver(driver);
                    }}
                  >
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <Box
                            sx={{
                              width: 25,
                              height: 25,
                              backgroundColor: driver?.color,
                              "&:hover": {
                                backgroundColor: driver?.color,
                                opacity: [0.9, 0.8, 0.7],
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          {driver?.driverNumber}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>{driver?.driverName}</TableCell>
                    <TableCell>{driver?.phone}</TableCell>
                    {/* <TableCell>{driver?.provider}</TableCell> */}
                    {/* <TableCell>
                    <Button onClick={() => {}}>Edit</Button>
                  </TableCell> */}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Records to Display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AlertDialog
        customControls={false}
        open={openEditDriver}
        handleClose={() => {
          setOpenEditDriver(false);
        }}
        dialogContent={
          <EditDriver
            handleClose={() => setOpenEditDriver(false)}
            selectedDriver={selectedDriver}
            setSelectedDriver={setSelectedDriver}
          />
        }
        dialogTitle="Edit Driver"
      />
    </div>
  );
}
