import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { primaryButtonColor } from "constants";
import { primaryButtonHoverColor } from "constants";
import { useForm } from "react-hook-form";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { addressSearch, GAutoComplete } from "Components/GAutoComplete";
import { getOrganizationSettings } from "API";
import RenderInput from "Templates/UI/RenderInput";
import { getPricingLists } from "Components/Pricing/functions";
import { getSurchargeSets } from "Components/Pricing/SurchargeSets/functions";
import { addCustomer } from "API/Customers";
import { VishcorpContext } from "Context";
import AddCostCenter from "./AddCostCenter";

export default function AddCustomer(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { orgSettings } = useContext(VishcorpContext);
  const [customerSettings, setCustomerSettings] = useState(undefined);

  const [confirmAddCustomer, setConfirmAddCustomer] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(undefined);
  const { handleClose, refresh } = props;

  const [pickupOptions, setPickupOptions] = useState([]);

  const [pricingLists, setPricingLists] = useState(undefined);
  const [surchargeSets, setSurchargeSets] = useState(undefined);

  const [costCenters, setCostCenters] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState(undefined);
  const [openAddCostCenter, setOpenAddCostCenter] = useState(false);
  const [openDeleteCostCenter, setOpenDeleteCostCenter] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);

  useEffect(() => {
    (async () => {
      await getPricingLists(
        orgSettings?.organizationID,
        orgSettings?.organizationTablePrefix
      )
        .then((res) => {
          setPricingLists(res);
        })
        .catch(console.error);
      await getSurchargeSets(
        orgSettings?.organizationID,
        orgSettings?.organizationTablePrefix
      )
        .then((res) => {
          setSurchargeSets(res);
        })
        .catch(console.error);
    })();
  }, [orgSettings]);

  useEffect(() => {
    getOrganizationSettings(orgSettings?.organizationID).then((res) =>
      setCustomerSettings(res?.organizationSettings?.customerSettings)
    );
  }, [orgSettings]);

  const updateCustomerDetails = (property, value) => {
    setCustomerDetails((preVal) => ({
      ...preVal,
      [property]: value,
    }));
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <Paper style={{ padding: 20, margin: 5 }}> */}
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginLeft: 5 }}>
          <FormLabel style={{ fontSize: "2rem" }}>New Customer</FormLabel>
        </Grid>
      </Grid>
      <br />
      {/* <TextField
          size="small"
          error={errors?.customerNumber && true}
          helperText={errors?.customerNumber?.message}
          {...register("customerNumber", {
            required: "Required*",
          })}
          label="Number"
          variant="outlined"
          value={customerDetails?.customerNumber ?? ""}
          onChange={(e) =>
            updateCustomerDetails("customerNumber", e?.target?.value)
          }
          style={{ width: "100%", marginBottom: 15 }}
        /> */}
      <TextField
        size="small"
        label="Name"
        error={errors?.customerName && true}
        helperText={errors?.customerName?.message}
        {...register("customerName", {
          required: "Required*",
        })}
        variant="outlined"
        value={customerDetails?.customerName ?? ""}
        onChange={(e) =>
          updateCustomerDetails("customerName", e?.target?.value)
        }
        style={{ width: "100%", marginBottom: 15 }}
      />
      <TextField
        size="small"
        {...register("discount", {
          required: "Required*",
        })}
        error={errors?.discount && true}
        helperText={errors?.discount?.message}
        label="Discount %"
        variant="outlined"
        value={customerDetails?.discount ?? ""}
        onChange={(e) => {
          const regex = /^[0-9\b]+$/;
          if (
            (e.target.value === "" || regex.test(e.target.value)) &&
            e?.target?.value?.length < 3
          ) {
            updateCustomerDetails("discount", e?.target?.value);
          }
        }}
        style={{ width: "100%", marginBottom: 15 }}
      />
      <FormControl
        fullWidth
        style={{ width: "100%", marginBottom: 15 }}
        size="small"
      >
        <InputLabel id="price-list-label">Price List</InputLabel>
        <Select
          {...register("pricingList", {
            required: "Required*",
          })}
          error={errors?.pricingList && true}
          labelId="price-list-label"
          label={"Price List"}
          fullWidth
          value={customerDetails?.pricingList ?? ""}
          onChange={(e) => {
            updateCustomerDetails("pricingList", e?.target?.value);
          }}
        >
          {pricingLists?.length > 0 &&
            pricingLists?.map((e, i) => (
              <MenuItem value={e?.pricingListName} key={i}>
                {e?.pricingListName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        fullWidth
        style={{ width: "100%", marginBottom: 15 }}
        size="small"
      >
        <InputLabel id="surcharge-list-label">Surcharge List</InputLabel>
        <Select
          {...register("surchargeSet", {
            required: "Required*",
          })}
          error={errors?.surchargeSet && true}
          labelId="surcharge-list-label"
          label={"Surcharge List"}
          fullWidth
          value={customerDetails?.surchargeSet ?? ""}
          onChange={(e) => {
            updateCustomerDetails("surchargeSet", e?.target?.value);
          }}
        >
          {surchargeSets?.length > 0 &&
            surchargeSets?.map((e, i) => (
              <MenuItem value={e?.surchargeSetName} key={i}>
                {e?.surchargeSetName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        fullWidth
        style={{ width: "100%", marginBottom: 15 }}
        size="small"
      >
        <InputLabel id="billing-group-label">Billing Group</InputLabel>
        <Select
          labelId="billing-group-label"
          label={"Billing Group"}
          fullWidth
          defaultValue=""
        ></Select>
      </FormControl>
      <Autocomplete
        options={pickupOptions ?? []}
        filterOptions={(x) => x}
        autoComplete
        onKeyDown={async (e, value) => {
          switch (e?.key) {
            case "Enter":
              const pAddressDetails = await addressSearch(e, value);
              if (!pAddressDetails) {
                return;
              }
              updateCustomerDetails("addressDetails", {
                ...pAddressDetails,
                country: pAddressDetails?.address_components?.find((e) =>
                  e?.types?.includes("country")
                )?.long_name,
                postCode: pAddressDetails?.address_components?.find((e) =>
                  e?.types?.includes("postal_code")
                )?.long_name,
                latitude: pAddressDetails?.geometry.location.lat(),
                longitude: pAddressDetails?.geometry.location.lng(),
                city: pAddressDetails?.address_components?.find((e) =>
                  e?.types?.includes("locality")
                )?.long_name,
              });
              updateCustomerDetails(
                "country",
                pAddressDetails?.address_components?.find((e) =>
                  e?.types?.includes("country")
                )?.long_name
              );
              updateCustomerDetails(
                "postCode",
                pAddressDetails?.address_components?.find((e) =>
                  e?.types?.includes("postal_code")
                )?.long_name
              );
              updateCustomerDetails(
                "latitude",
                pAddressDetails?.geometry.location.lat()
              );
              updateCustomerDetails(
                "longitude",
                pAddressDetails?.geometry.location.lng()
              );
              updateCustomerDetails(
                "city",
                pAddressDetails?.address_components?.find((e) =>
                  e?.types?.includes("locality")
                )?.long_name
              );
              updateCustomerDetails(
                "address",
                pAddressDetails?.formatted_address
              );
              break;
            default:
              break;
          }
        }}
        onChange={async (e, value) => {
          const pAddressDetails = await addressSearch(e, value);
          if (!pAddressDetails) {
            return;
          }
          updateCustomerDetails("addressDetails", {
            ...pAddressDetails,
            country: pAddressDetails?.address_components?.find((e) =>
              e?.types?.includes("country")
            )?.long_name,
            postCode: pAddressDetails?.address_components?.find((e) =>
              e?.types?.includes("postal_code")
            )?.long_name,
            latitude: pAddressDetails?.geometry.location.lat(),
            longitude: pAddressDetails?.geometry.location.lng(),
            city: pAddressDetails?.address_components?.find((e) =>
              e?.types?.includes("locality")
            )?.long_name,
          });
          updateCustomerDetails(
            "country",
            pAddressDetails?.address_components?.find((e) =>
              e?.types?.includes("country")
            )?.long_name
          );
          updateCustomerDetails(
            "postCode",
            pAddressDetails?.address_components?.find((e) =>
              e?.types?.includes("postal_code")
            )?.long_name
          );
          updateCustomerDetails(
            "latitude",
            pAddressDetails?.geometry.location.lat()
          );
          updateCustomerDetails(
            "longitude",
            pAddressDetails?.geometry.location.lng()
          );
          updateCustomerDetails(
            "city",
            pAddressDetails?.address_components?.find((e) =>
              e?.types?.includes("locality")
            )?.long_name
          );

          updateCustomerDetails("address", pAddressDetails?.formatted_address);
        }}
        // value={customerDetails?.address ?? ""}
        // onChange={(e) => updateCustomerDetails("address", e?.target?.value)}
        style={{ width: "100%", marginBottom: 15 }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            error={errors?.address && true}
            helperText={errors?.address?.message}
            {...register("address", {
              required: "Required*",
            })}
            label="Address"
            variant="outlined"
            onChange={(e) => {
              GAutoComplete(e?.target?.value)
                .then((res) => {
                  // console.log("Suggestions===>", res);
                  setPickupOptions(
                    res?.map((e) => ({ label: e?.description, data: e }))
                  );
                })
                .catch(console.error);
            }}
          />
        )}
      />
      <TextField
        {...register("phone", {
          required: "Required*",
        })}
        error={errors?.phone && true}
        helperText={errors?.phone?.message}
        size="small"
        label="Phone"
        variant="outlined"
        value={customerDetails?.phone ?? ""}
        onChange={(e) => {
          const onlyNums = e.target.value.replace(/[^0-9]/g, "");
          if (onlyNums.length < 10) {
            updateCustomerDetails("phone", onlyNums);
          } else if (onlyNums.length === 10) {
            const number = onlyNums.replace(
              /(\d{3})(\d{3})(\d{4})/,
              "($1) $2-$3"
            );
            updateCustomerDetails("phone", number);
          }
        }}
        style={{ width: "100%", marginBottom: 15 }}
      />
      <TextField
        {...register("fax", {
          required: "Required*",
        })}
        error={errors?.fax && true}
        helperText={errors?.fax?.message}
        size="small"
        label="Fax"
        variant="outlined"
        value={customerDetails?.fax ?? ""}
        onChange={(e) => updateCustomerDetails("fax", e?.target?.value)}
        style={{ width: "100%", marginBottom: 15 }}
      />
      <TextField
        {...register("email", {
          required: "Required*",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format",
          },
        })}
        error={errors?.email && true}
        helperText={errors?.email?.message}
        size="small"
        label="E-Mail"
        variant="outlined"
        value={customerDetails?.email ?? ""}
        onChange={(e) => {
          // console.log(
          //   String(e?.target?.value)
          //     .toLowerCase()
          //     .match(
          //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          //     )
          // );
          updateCustomerDetails("email", e?.target?.value);
        }}
        style={{ width: "100%", marginBottom: 15 }}
      />
      <FormControlLabel
        control={<Checkbox />}
        label="Show Cost Centers"
        checked={customerDetails?.showCostCenters}
        onClick={() => {
          setCustomerDetails((pre) => {
            return { ...pre, showCostCenters: !pre?.showCostCenters };
          });
        }}
      />
      {customerDetails?.showCostCenters && (
        <div>
          <div>
            <br />
            <Typography variant="h5">Cost Centers</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Cost Center</b>
                    </TableCell>
                    <TableCell>
                      <b>Address</b>
                    </TableCell>
                    <TableCell>
                      <b>City</b>
                    </TableCell>
                    <TableCell>
                      <b>Postal</b>
                    </TableCell>
                    <TableCell>
                      <b>Province</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {costCenters?.length > 0 ? (
                    costCenters?.map((e, i) => (
                      <TableRow
                        hover
                        selected={
                          selectedCostCenter?.costCenterName ===
                          e?.costCenterName
                        }
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedCostCenter(e);
                        }}
                      >
                        <TableCell>{e?.costCenterName}</TableCell>
                        <TableCell>{e?.address?.address}</TableCell>
                        <TableCell>{e?.city}</TableCell>
                        <TableCell>{e?.postalCode}</TableCell>
                        <TableCell>{e?.province}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setOpenAddCostCenter(true);
                }}
                sx={{ marginRight: 5 }}
              >
                Add
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  if (selectedCostCenter === undefined) {
                    return;
                  }
                  setOpenDeleteCostCenter(true);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      )}
      <FormControlLabel control={<Checkbox />} label="Allow Price Check" />

      {customerSettings &&
        customerSettings
          ?.filter((e) => !e?.isDefault && e?.visible)
          ?.map((e, i) => (
            <RenderInput
              value={customerDetails?.[e?.id]}
              setValue={(val) => {
                updateCustomerDetails(e?.id, val);
              }}
              register={register}
              errors={errors}
              type={e?.type}
              required={e?.required}
              label={e?.name}
              id={e?.id}
              key={e?.id}
              control={control}
              options={e?.options ?? []}
              defaultValue={e?.defaultValue}
            />
          ))}
      <br />
      <br />
      <Button
        sx={{
          borderRadius: 0,
          backgroundColor: primaryButtonColor,
          "&:hover": {
            backgroundColor: primaryButtonHoverColor,
          },
        }}
        variant="contained"
        onClick={handleSubmit(() => {
          setConfirmAddCustomer(true);
        })}
      >
        Add Customer
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: 10 }}
        sx={{
          borderRadius: 0,
        }}
        color="error"
        onClick={() => {
          handleClose();
        }}
      >
        Cancel
      </Button>
      {/* </Paper> */}
      <AlertDialog
        open={confirmAddCustomer}
        handleClose={() => {
          setConfirmAddCustomer(false);
        }}
        handleConfirm={async () => {
          //   const userInfo = await Auth.currentUserInfo();
          addCustomer(
            { ...customerDetails, costCenters },
            orgSettings?.organizationID,
            orgSettings?.organizationTablePrefix
          ).then((res) => {
            setConfirmAddCustomer(false);
            refresh();
            handleClose();
          });
          //   resetForm();
        }}
        dialogTitle="Confirm Add Driver"
        // dialogContent={
        //   <div>
        //     Confirm New Driver?
        //   </div>
        // }
      />
      <AlertDialog
        customControls={false}
        open={openAddCostCenter}
        handleClose={() => {
          setOpenAddCostCenter(false);
        }}
        dialogTitle={`Add Cost Center`}
        dialogContent={
          <AddCostCenter
            handleClose={() => {
              setOpenAddCostCenter(false);
            }}
            openAlertMessage={openAlertMessage}
            setOpenAlertMessage={setOpenAlertMessage}
            onAddCostCenter={async (e) => {
              setCostCenters((pre) => [...pre, e]);
              setOpenAddCostCenter(false);
            }}
          />
        }
        handleConfirm={async () => {
          setOpenAddCostCenter(false);
        }}
      />
      <AlertDialog
        open={openDeleteCostCenter}
        handleClose={() => {
          setOpenDeleteCostCenter(false);
        }}
        handleConfirm={async () => {
          setCostCenters((pre) =>
            pre?.filter(
              (e) => e?.costCenterID !== selectedCostCenter?.costCenterID
            )
          );
          setOpenDeleteCostCenter(false);
        }}
        dialogTitle={`Confirm Delete Cost Center: ${customerDetails?.costCenterName}`}
      />
    </Box>
  );
}
