import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, FormLabel, Grid, TextField } from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { updateDriverCommission } from "API/Drivers";
import { VishcorpContext } from "Context";

export default function EditDriver(props) {
  const { selectedDriver, setSelectedDriver, handleClose } = props;
  const [deductions, setDeductions] = useState(
    JSON.parse(selectedDriver?.deductions)
  );
  const [openUpdateDriver, setOpenUpdateDriver] = useState(false);

  const { orgSettings, refreshDrivers } = useContext(VishcorpContext);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 100 }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Property</b>
              </TableCell>
              <TableCell>
                <b>Value</b>
              </TableCell>
            </TableRow>
          </TableHead>
          {selectedDriver && (
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Driver ID</b>
                </TableCell>
                <TableCell>{selectedDriver?.driverID}</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Driver Number</b>
                </TableCell>
                <TableCell>{selectedDriver?.driverNumber}</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Driver Name</b>
                </TableCell>
                <TableCell>{selectedDriver?.driverName}</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Contact Phone</b>
                </TableCell>
                <TableCell>{selectedDriver?.phone}</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Commission %</b>
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    label="Commission"
                    fullWidth
                    variant="outlined"
                    value={selectedDriver?.commission ?? ""}
                    onChange={(e) => {
                      const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
                      if (e.target.value === "" || regex.test(e.target.value)) {
                        if (
                          parseFloat(e?.target?.value) >= 0 &&
                          parseFloat(e?.target?.value) <= 100
                        ) {
                          setSelectedDriver((pre) => ({
                            ...pre,
                            commission: e?.target?.value,
                          }));
                        }
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormLabel style={{ fontSize: "1.25rem" }}>Deductions</FormLabel>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Biker"
            variant="outlined"
            value={deductions?.biker ?? ""}
            onChange={(e) => {
              const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
              if (e.target.value === "" || regex.test(e.target.value)) {
                if (
                  parseFloat(e?.target?.value) >= 0 &&
                  parseFloat(e?.target?.value) <= 100
                ) {
                  setDeductions((pre) => {
                    return { ...pre, biker: e?.target?.value };
                  });
                }
              }
            }}
            style={{ width: "100%", marginBottom: 15, marginTop: 15 }}
          />
          <TextField
            size="small"
            label="Radio"
            variant="outlined"
            value={deductions?.radio ?? ""}
            onChange={(e) => {
              const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
              if (e.target.value === "" || regex.test(e.target.value)) {
                if (
                  parseFloat(e?.target?.value) >= 0 &&
                  parseFloat(e?.target?.value) <= 100
                ) {
                  setDeductions((pre) => {
                    return { ...pre, radio: e?.target?.value };
                  });
                }
              }
            }}
            style={{ width: "100%", marginBottom: 15 }}
          />
          <TextField
            size="small"
            label="Uniform"
            variant="outlined"
            value={deductions?.uniform ?? ""}
            onChange={(e) => {
              const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
              if (e.target.value === "" || regex.test(e.target.value)) {
                if (
                  parseFloat(e?.target?.value) >= 0 &&
                  parseFloat(e?.target?.value) <= 100
                ) {
                  setDeductions((pre) => {
                    return { ...pre, uniform: e?.target?.value };
                  });
                }
              }
            }}
            style={{ width: "100%", marginBottom: 15 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Cash Calls"
            variant="outlined"
            value={deductions?.cashCalls ?? ""}
            onChange={(e) => {
              const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
              if (e.target.value === "" || regex.test(e.target.value)) {
                if (
                  parseFloat(e?.target?.value) >= 0 &&
                  parseFloat(e?.target?.value) <= 100
                ) {
                  setDeductions((pre) => {
                    return { ...pre, cashCalls: e?.target?.value };
                  });
                }
              }
            }}
            style={{ width: "100%", marginBottom: 15, marginTop: 15 }}
          />
          <TextField
            size="small"
            label="Advances"
            variant="outlined"
            value={deductions?.advances ?? ""}
            onChange={(e) => {
              const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
              if (e.target.value === "" || regex.test(e.target.value)) {
                if (
                  parseFloat(e?.target?.value) >= 0 &&
                  parseFloat(e?.target?.value) <= 100
                ) {
                  setDeductions((pre) => {
                    return { ...pre, advances: e?.target?.value };
                  });
                }
              }
            }}
            style={{ width: "100%", marginBottom: 15 }}
          />
          <TextField
            size="small"
            label="Others"
            variant="outlined"
            value={deductions?.others ?? ""}
            onChange={(e) => {
              const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
              if (e.target.value === "" || regex.test(e.target.value)) {
                if (
                  parseFloat(e?.target?.value) >= 0 &&
                  parseFloat(e?.target?.value) <= 100
                ) {
                  setDeductions((pre) => {
                    return { ...pre, others: e?.target?.value };
                  });
                }
              }
            }}
            style={{ width: "100%", marginBottom: 15 }}
          />
          <TextField
            size="small"
            label="Incomplete Waybills"
            variant="outlined"
            value={deductions?.incompleteWaybills ?? ""}
            onChange={(e) => {
              const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
              if (e.target.value === "" || regex.test(e.target.value)) {
                if (
                  parseFloat(e?.target?.value) >= 0 &&
                  parseFloat(e?.target?.value) <= 100
                ) {
                  setDeductions((pre) => {
                    return { ...pre, incompleteWaybills: e?.target?.value };
                  });
                }
              }
            }}
            style={{ width: "100%", marginBottom: 15 }}
          />
        </Grid>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setOpenUpdateDriver(true);
            }}
            sx={{ marginRight: 2 }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              handleClose();
            }}
            color="error"
          >
            Cancel
          </Button>
        </div>
      </Grid>
      <AlertDialog
        open={openUpdateDriver}
        handleClose={() => {
          setOpenUpdateDriver(false);
        }}
        handleConfirm={async () => {
          await updateDriverCommission(
            selectedDriver?.commission,
            JSON.stringify(deductions),
            selectedDriver?.driverID,
            orgSettings?.organizationTablePrefix
          );
          await refreshDrivers();
          setOpenUpdateDriver(false);
          handleClose();
        }}
        dialogTitle={`Confirm Update Driver: ${selectedDriver?.driverNumber}`}
      />
    </div>
  );
}
