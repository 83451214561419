export const getCustomers = (organizationTablePrefix) =>
  fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=GET_CUSTOMERS",
    {
      method: "POST",
      body: JSON.stringify({
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.customers?.Items)
    .catch(console.error);

export const getCustomer = (customerID, organizationTablePrefix) =>
  fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=GET_CUSTOMER",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_CUSTOMER",
        customerID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res)
    .catch(console.error);

export const addCostCenter = async (
  customerID,
  costCenterDetails,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "ADD_COST_CENTER",
        customerID,
        costCenterDetails,
        organizationTablePrefix,
        organizationID,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const deleteCostCenter = async (
  customerID,
  costCenterID,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "DELETE_COST_CENTER",
        customerID,
        costCenterID,
        organizationTablePrefix,
        organizationID,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getCostCenter = async (
  customerID,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_COST_CENTERS",
        customerID,
        organizationTablePrefix,
        organizationID,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};
