export const getCustomers = async (organizationTablePrefix) =>
  await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=GET_CUSTOMERS",
    {
      method: "POST",
      body: JSON.stringify({
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);

export const addCustomer = async (
  customerDetails,
  organizationID,
  organizationTablePrefix
) =>
  await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "CREATE_CUSTOMER",
        customerName: customerDetails?.customerName,
        customerNumber: customerDetails?.customerNumber,
        address: customerDetails?.address,
        email: customerDetails?.email,
        fax: customerDetails?.fax,
        phone: customerDetails?.phone,
        pricingList: customerDetails?.pricingList,
        surchargeSet: customerDetails?.surchargeSet,
        billingGroup: customerDetails?.billingGroup ?? "",
        discount: customerDetails?.discount,
        customCustomerDetails: { customerDetails },
        organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);

export const deleteCustomer = async (customerID, organizationTablePrefix) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=DELETE_CUSTOMER",
    {
      method: "POST",
      body: JSON.stringify({
        action: "DELETE_CUSTOMER",
        customerID: customerID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};
