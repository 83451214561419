export const addSurchargeSet = async (
  surchargeSetName,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "ADD_SURCHARGE_SET",
        surchargeSetName,
        organizationID: organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getSurchargeSets = async (
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_SURCHARGE_SETS",
        organizationID: organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.response?.Items)
    .catch(console.error);
};

export const updateSurchargeSet = async (
  surchargeSetName,
  surchargeSetDetails,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_SURCHARGE_SET",
        organizationID: organizationID,
        surchargeSetName,
        surchargeSetDetails,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.response?.Items)
    .catch(console.error);
};

export const removeSurchargeSet = async (
  surchargeSetName,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "REMOVE_SURCHARGE_SET",
        organizationID: organizationID,
        surchargeSetName,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.response?.Items)
    .catch(console.error);
};
