import { TextField } from "@mui/material";

export default function Phone(props) {
  const {
    value,
    defaultValue,
    label,
    required,
    register,
    errors,
    setValue,
    style,
  } = props;
  return (
    <TextField
      label={label}
      fullWidth
      style={{ ...(style ?? {}) }}
      value={value ?? defaultValue ?? ""}
      // defaultValue={defaultValue}
      size="small"
      {...(register &&
        register(label, {
          required: required ? "Required*" : false,
        }))}
      variant="outlined"
      error={errors?.[label] && required}
      helperText={required && errors?.[label]?.message}
      onChange={(e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, "");
        if (onlyNums.length < 10) {
          setValue(onlyNums);
        } else if (onlyNums.length === 10) {
          const number = onlyNums.replace(
            /(\d{3})(\d{3})(\d{4})/,
            "($1) $2-$3"
          );
          setValue(number);
        }
      }}
      // value={value ?? ""}
    />
  );
}
