import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function RightClickMenu(props) {
  const { contextMenu, setContextMenu, handleOptimize } = props;
  return (
    <Menu
      onClose={() => setContextMenu(undefined)}
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      open={contextMenu !== undefined}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== undefined
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      <MenuItem onClick={()=>handleOptimize("Pickup")}>Optimize Pickup</MenuItem>
      <MenuItem onClick={()=>handleOptimize("Destination")}>
        Optimize Destination
      </MenuItem>
      <MenuItem>Customize</MenuItem>
    </Menu>
  );
}
