import React, { useState } from "react";
import EditOrder from "./EditOrder";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import { FormLabel, Grid, Button } from "@mui/material";
import OrderLists from "./OrderLists";
import { VishcorpContext } from "Context";
import { useContext } from "react";
import { updateOrder } from "API/Orders";

export default function IncomingOrders(props) {
  const {
    incomingOrders,
    refreshOrder,
    selectedOrder,
    setSelectedOrder,
    orgSettings,
  } = useContext(VishcorpContext);
  const [editOrder, setEditOrder] = useState(false);
  const [modifiedStatus, setModifiedStatus] = useState(undefined);
  const [onUpdateOrder, setOnUpdateOrder] = useState(false);
  const [assignedDriver, setAssignedDriver] = useState(undefined);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormLabel sx={{ fontSize: "2rem" }}>Incoming Orders</FormLabel>
        <br />
        <br />
        <OrderLists
          orders={incomingOrders}
          onEditClick={(e) => {
            // setEditOrder(true);
            setSelectedOrder(e);
            setModifiedStatus(e?.orderStatus);
          }}
        />
      </Grid>
      {editOrder && (
        <AlertDialog
          open={editOrder}
          handleClose={() => {
            setEditOrder(false);
          }}
          customControls={false}
          dialogContent={
            <Grid sx={{ position: "static" }}>
              <FormLabel sx={{ fontSize: "2rem" }}>Edit Order</FormLabel>
              <br />
              <br />
              <EditOrder
                selectedOrder={selectedOrder}
                onStatusChange={(e) => setModifiedStatus(e)}
                onDriverAssigned={(e) => setAssignedDriver(e)}
              />
              <br />
              <Grid
                container
                sx={{
                  display: "flex",
                  // justifyContent: "right",
                  // alignContent: "right",
                }}
                spacing={1}
              >
                <Grid item>
                  <Button
                    sx={{ borderRadius: 0 }}
                    variant="contained"
                    onClick={() => {
                      if (modifiedStatus !== selectedOrder?.orderStatus) {
                        setOnUpdateOrder(true);
                      }
                    }}
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ borderRadius: 0 }}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setEditOrder(false);
                      setSelectedOrder(undefined);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}
      <AlertDialog
        open={onUpdateOrder}
        handleClose={() => {
          setOnUpdateOrder(false);
        }}
        handleConfirm={() => {
          updateOrder(
            selectedOrder,
            modifiedStatus,
            assignedDriver,
            orgSettings?.organizationTablePrefix
          ).then((res) => {
            refreshOrder();
            console.log("Status Updated: ", res);
          });
          setEditOrder(false);
          setOnUpdateOrder(false);
        }}
        dialogTitle={
          <b>
            Confirm Update.
            <br />
            {`Order: ${selectedOrder?.orderID}.`}
          </b>
        }
        dialogContent={
          <b>
            {`Status Changed: ${selectedOrder?.orderStatus} -> ${modifiedStatus}`}
          </b>
        }
      />
    </Grid>
  );
}
