export const getDrivers = (organizationTablePrefix) =>
  fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=GET_DRIVERS",
    {
      method: "POST",
      body: JSON.stringify({
        organizationTablePrefix: organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);

export const deleteDriver = async (driverID, organizationTablePrefix) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=DELETE_DRIVER",
    {
      method: "POST",
      body: JSON.stringify({
        action: "DELETE_DRIVER",
        driverID: driverID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const createDriver = async (
  driverDetails,
  organizationID,
  organizationTablePrefix
) =>
  await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "CREATE_DRIVER",
        driverName: driverDetails?.driverName,
        driverNumber: driverDetails?.driverNumber,
        commission: driverDetails?.commission,
        phone: driverDetails?.phone,
        email: driverDetails?.email,
        provider: driverDetails?.provider ?? "ACS Wireless",
        color: driverDetails?.color ?? "#ffffff",
        dispatchingType: driverDetails?.dispatchingType,
        deductions: JSON.stringify({
          biker: driverDetails?.biker,
          radio: driverDetails?.radio,
          uniform: driverDetails?.uniform,
          cashCalls: driverDetails?.cashCalls,
          advances: driverDetails?.advances,
          others: driverDetails?.others,
          incompleteWaybills: driverDetails?.incompleteWaybills,
        }),
        customDriverDetails: { driverDetails },
        organizationTablePrefix,
        organizationID,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);

export const updateDriverOptimizedOrder = async (
  driverID,
  driverOptimizedOrders,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_DRIVER_OPTIMIZED_ORDER",
        driverID,
        driverOptimizedOrders,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const updateDriverCommission = async (
  commission,
  deductions,
  driverID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_DRIVER_COMMISSION",
        commission,
        deductions,
        driverID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};
