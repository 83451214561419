import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import AddCostCenter from "./AddCostCenter";
import { addCostCenter, deleteCostCenter, getCustomer } from "./functions";
import { VishcorpContext } from "Context";
// import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// import { VishcorpContext } from "Context";

export default function EditCustomer(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const { selectedCustomer, setSelectedCustomer } = props;
  const [costCenters, setCostCenters] = useState(selectedCustomer?.costCenters);
  const [selectedCostCenter, setSelectedCostCenter] = useState(undefined);
  const [openAddCostCenter, setOpenAddCostCenter] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [openDeleteCostCenter, setOpenDeleteCostCenter] = useState(false);

  const refreshCustomer = async () => {
    if (!selectedCustomer?.customerID) {
      return;
    }
    await getCustomer(
      selectedCustomer?.customerID,
      orgSettings?.organizationTablePrefix
    )
      .then((res) => {
        setSelectedCustomer(res);
        setCostCenters(res?.costCenters ?? []);
      })
      .catch(console.error);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 100 }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Property</b>
              </TableCell>
              <TableCell>
                <b>Value</b>
              </TableCell>
            </TableRow>
          </TableHead>
          {selectedCustomer && (
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Customer Number</b>
                </TableCell>
                <TableCell>{selectedCustomer?.customerNumber}</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Customer Number</b>
                </TableCell>
                <TableCell>{selectedCustomer?.customerNumber}</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Customer Name</b>
                </TableCell>
                <TableCell>{selectedCustomer?.customerName}</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Contact Phone</b>
                </TableCell>
                <TableCell>{selectedCustomer?.phone}</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>E-mail</b>
                </TableCell>
                <TableCell>{selectedCustomer?.email}</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <b>Address</b>
                </TableCell>
                <TableCell>{selectedCustomer?.address}</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <FormControlLabel
        label="Show Cost Centers"
        control={
          <Checkbox
            checked={selectedCustomer?.showCostCenters}
            onClick={() => {
              setSelectedCustomer((pre) => {
                return { ...pre, showCostCenters: !pre?.showCostCenters };
              });
            }}
          />
        }
      />
      {selectedCustomer?.showCostCenters && (
        <div>
          <br />
          <Typography variant="h5">Cost Centers</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Cost Center</b>
                  </TableCell>
                  <TableCell>
                    <b>Address</b>
                  </TableCell>
                  <TableCell>
                    <b>City</b>
                  </TableCell>
                  <TableCell>
                    <b>Postal</b>
                  </TableCell>
                  <TableCell>
                    <b>Province</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {costCenters?.length > 0 ? (
                  costCenters?.map((e, i) => (
                    <TableRow
                      hover
                      selected={
                        selectedCostCenter?.costCenterName === e?.costCenterName
                      }
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedCostCenter(e);
                      }}
                    >
                      <TableCell>{e?.costCenterName}</TableCell>
                      <TableCell>{e?.address?.address}</TableCell>
                      <TableCell>{e?.city}</TableCell>
                      <TableCell>{e?.postalCode}</TableCell>
                      <TableCell>{e?.province}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setOpenAddCostCenter(true);
              }}
              sx={{ marginRight: 5 }}
            >
              Add
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                if (selectedCostCenter === undefined) {
                  return;
                }
                setOpenDeleteCostCenter(true);
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      <AlertDialog
        customControls={false}
        open={openAddCostCenter}
        handleClose={() => {
          setOpenAddCostCenter(false);
        }}
        dialogTitle={`Add Cost Center`}
        dialogContent={
          <AddCostCenter
            handleClose={() => {
              setOpenAddCostCenter(false);
            }}
            openAlertMessage={openAlertMessage}
            setOpenAlertMessage={setOpenAlertMessage}
            errorMessage={errorMessage}
            onAddCostCenter={async (e) => {
              const addCCRes = await addCostCenter(
                selectedCustomer?.customerID,
                e,
                orgSettings?.organizationID,
                orgSettings?.organizationTablePrefix
              );
              // setCostCenters((pre) => [...(pre ?? []), e]);
              if (String(addCCRes?.response?.statusCode) === "400") {
                setErrorMessage(addCCRes?.response);
                setOpenAlertMessage(true);
              } else {
                await refreshCustomer();
                setOpenAddCostCenter(false);
              }
            }}
          />
        }
      />
      <AlertDialog
        open={openDeleteCostCenter}
        handleClose={() => {
          setOpenDeleteCostCenter(false);
        }}
        handleConfirm={async () => {
          await deleteCostCenter(
            selectedCustomer?.customerID,
            selectedCostCenter?.costCenterID,
            orgSettings?.organizationID,
            orgSettings?.organizationTablePrefix
          );
          await refreshCustomer();
          setOpenDeleteCostCenter(false);
        }}
        dialogTitle={`Confirm Delete Cost Center: ${selectedCostCenter?.costCenterName}`}
      />
    </div>
  );
}
