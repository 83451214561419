export const getOrganizationSettings = async (organizationID) => {
  try {
    const pOrganizationSettings = await fetch(
      "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
      {
        method: "POST",
        body: JSON.stringify({
          action: "GET_ORGANIZATION_SETTINGS",
          organizationID: organizationID,
        }),
      }
    )
      .then((res) => res.json())
      .catch(console.error);
    return pOrganizationSettings?.response?.Items?.[0];
  } catch (error) {
    return error;
  }
};

export const getDriverOrders = async (
  driverNumber,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/driver-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_DRIVER_ORDERS",
        assignedDriver: driverNumber,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.result?.Items)
    .catch(console.error);
};

export const addAttribute = async (
  settingType,
  settingDetails,
  organizationID,
  organizationTablePrefix
) => {
  await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "NEW_ORGANIZATION_SETTING_ATTRIBUTE",
        organizationID: organizationID,
        settingDetails,
        settingType,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => {
      // console.log(res);
    })
    .catch(console.error);
};

export const getOrderAttachments = async (orderID, organizationTablePrefix) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ORDER_ATTACHMENTS",
        orderID: orderID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getOrderAttachment = async (
  orderID,
  fileName,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ORDER_ATTACHMENT",
        orderID: orderID,
        fileName: fileName,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const deleteOrderAttachment = async (
  orderID,
  fileName,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "DELETE_ORDER_ATTACHMENT",
        orderID: orderID,
        fileName: fileName,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getOrderDeliveryAttachments = async (
  orderID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ORDER_DELIVERY_ATTACHMENTS",
        orderID: orderID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getOrderDeliveryAttachment = async (
  orderID,
  fileName,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ORDER_DELIVERY_ATTACHMENT",
        orderID: orderID,
        fileName: fileName,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const deleteOrderDeliveryAttachment = async (
  orderID,
  fileName,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "DELETE_ORDER_DELIVERY_ATTACHMENT",
        orderID: orderID,
        fileName: fileName,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const deleteAttribtue = async (
  settingType,
  deleteItem,
  organizationID,
  organizationTablePrefix
) => {
  await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "DELETE_ORGANIZATION_SETTINGS",
        organizationID: organizationID,
        settingType,
        attributeName: deleteItem?.data?.name,
        index: deleteItem?.index,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => {
      // console.log(res);
    })
    .catch(console.error);
};

export const updateAttribute = async (
  settingType,
  item,
  organizationID,
  organizationTablePrefix
) => {
  await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_ORGANIZATION_SETTINGS",
        organizationID: organizationID,
        settingType,
        attributeName: item?.data?.name,
        settingDetails: item?.data,
        index: item?.index,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => {
      // console.log(res);
    })
    .catch(console.error);
};
