import { TextField } from "@mui/material";

export default function Email(props) {
  const {
    value,
    defaultValue,
    label,
    required,
    register,
    errors,
    setValue,
    style,
  } = props;
  return (
    <TextField
      style={{ ...(style ?? {}) }}
      label={label}
      fullWidth
      size="small"
      value={value ?? defaultValue ?? ""}
      {...(register &&
        register(label, {
          required: required ? "Required*" : false,
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format",
          },
        }))}
      // defaultValue={defaultValue}
      variant="outlined"
      error={errors?.[label] && required}
      helperText={required && errors?.[label]?.message}
      onChange={(e) => {
        setValue(e?.target?.value);
      }}
      // value={value ?? ""}
    />
  );
}
