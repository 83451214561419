import { TextField } from "@mui/material";

export default function NumberInput(props) {
  const {
    value,
    defaultValue,
    label,
    required,
    register,
    errors,
    setValue,
    type,
  } = props;
  return (
    <TextField
      label={label}
      fullWidth
      size="small"
      value={value ?? defaultValue ?? ""}
      {...(register &&
        register(label, {
          required: required ? "Required*" : false,
        }))}
      // defaultValue={defaultValue}
      variant="outlined"
      error={errors?.[label] && required}
      helperText={required && errors?.[label]?.message}
      onChange={(e) => {
        const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
        // const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
          if (
            (parseFloat(e?.target?.value) >= 0 &&
              parseFloat(e?.target?.value) <= 100) ||
            type !== "percentage"
          ) {
            setValue(e?.target?.value);
          }
        }
      }}
      // value={value ?? ""}
    />
  );
}
