export const getIncomingOrders = async (organizationTablePrefix) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=LIST_INCOMING_ORDERS",
    {
      method: "POST",
      body: JSON.stringify({
        organizationTablePrefix: organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};
export const getPickUpOrders = async (organizationTablePrefix) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=LIST_PICK_UP_ORDERS",
    {
      method: "POST",
      body: JSON.stringify({
        organizationTablePrefix: organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};
export const getActiveOrders = async (organizationTablePrefix) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=LIST_ACTIVE_ORDERS",
    {
      method: "POST",
      body: JSON.stringify({
        organizationTablePrefix: organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};
export const getCancelledOrders = async (organizationTablePrefix) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=LIST_CANCELLED_ORDERS",
    {
      method: "POST",
      body: JSON.stringify({
        organizationTablePrefix: organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};
export const getConfirmedOrders = async (organizationTablePrefix) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=LIST_CONFIRMED_ORDERS",
    {
      method: "POST",
      body: JSON.stringify({
        organizationTablePrefix: organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};
export const getDeliveredOrders = async (organizationTablePrefix) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management?action=LIST_DELIVERED_ORDERS",
    {
      method: "POST",
      body: JSON.stringify({
        organizationTablePrefix: organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const updateOrder = async (
  selectedOrder,
  modifiedStatus,
  assignedDriver,
  organizationTablePrefix
) =>
  await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_ORDER",
        orderID: selectedOrder?.orderID,
        orderTimestamp: selectedOrder?.orderTimestamp,
        orderStatus: modifiedStatus,
        assignedDriver: assignedDriver,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);

export const optimizeWayPoints = async (
  allOrders,
  startAddress,
  endAddress,
  optimizeType,
  organizationTablePrefix
) =>
  await fetch(
    `https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management`,
    {
      method: "POST",
      body: JSON.stringify({
        action: "OPTIMIZE_WAY_POINTS",
        source: {
          latitude: startAddress?.latitude,
          longitude: startAddress?.longitude,
        },
        destinations: allOrders
          ?.filter((e) => e?.orderStatus !== "Active")
          ?.map((e) => ({
            latitude:
              optimizeType === "Pickup"
                ? e?.pickupDetails?.latitude
                : e?.deliveryDetails?.latitude,
            longitude:
              optimizeType === "Pickup"
                ? e?.pickupDetails?.longitude
                : e?.deliveryDetails?.longitude,
          })),
        endPoint: {
          latitude: endAddress?.latitude,
          longitude: endAddress?.longitude,
        },
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);

export const orderSearch = async (value, dateRange, organizationTablePrefix) =>
  await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/order-management",
    {
      method: "POST",
      body: JSON.stringify({
        action: "SEARCH",
        input: value,
        startDate: new Date(dateRange?.[0]).getTime(),
        endDate: new Date(dateRange?.[1]).getTime(),
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
