// import { Button } from "@mui/material";
import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { VishcorpContext } from "Context";
import moment from "moment";
import SecurityUpdateGoodIcon from "@mui/icons-material/SecurityUpdateGood";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { Tooltip } from "@mui/material";

export default function OrderLists(props) {
  const { drivers, orgSettings } = useContext(VishcorpContext);
  const { orders, onEditClick } = props;
  const getBackgroundColor = (status) => {
    switch (status) {
      case "Active":
        return "blue";
      case "Delivered":
        return "green";
      case "Incoming":
        return "orange";
      case "Cancel":
        return "red";
      default:
        return "black";
    }
  };
  const getStatusIcon = (status) => {
    switch (status) {
      case "Active":
        return <ThumbUpOffAltIcon />;
      case "Delivered":
        return <DoneOutlineIcon />;
      case "Incoming":
        return <ArrowDownwardIcon />;
      case "Cancel":
        return <CancelIcon />;
      case "Confirmed":
        return <SecurityUpdateGoodIcon />;
      case "Pick Up":
        return <ArrowUpwardIcon />;
      default:
        return "black";
    }
  };
  const getNewStatusMappings = (status) => {
    switch (status) {
      case "Active":
        return "Confirmed";
      case "Pick Up":
        return "Pick Up";
      case "Delivered":
        return "Delivered";
      case "Incoming":
        return "Incoming";
      case "Cancel":
        return "Cancelled";
      case "Confirmed":
        return "Dispatched";
      default:
        return "black";
    }
  };

  const addHours = (sTime, h) => {
    const time = new Date(sTime);
    time.setTime(time.getTime() + h * 60 * 60 * 1000);
    return time;
  };

  const getDeliveryTime = (orderItem) => {
    // orderItem?.parcelDetails?.serviceType; orderItem?.parcelDetails?.serviceType;
    if (orgSettings?.serviceOptions?.length > 0 && orderItem !== undefined) {
      const serviceOption = orgSettings?.serviceOptions?.find(
        (e) =>
          String(orderItem?.parcelDetails?.serviceType).toLowerCase() ===
          String(e?.name).toLowerCase()
      );
      const serviceHours = isNaN(parseFloat(serviceOption?.deliveryTime?.hours))
        ? 0
        : parseFloat(serviceOption?.deliveryTime?.hours);
      const serviceDayHours = isNaN(
        parseFloat(serviceOption?.deliveryTime?.days)
      )
        ? 0
        : parseFloat(serviceOption?.deliveryTime?.days);
      let momentDate;
      try {
        momentDate = moment(
          addHours(
            orderItem?.orderReadyTime ?? orderItem?.orderTimestamp,
            serviceHours + serviceDayHours * 24
          )
          // .toISOString()
        );
        return (
          <TableCell
            style={
              momentDate.isBefore(new Date())
                ? {
                    backgroundColor: "red",
                    color: "white",
                  }
                : {}
            }
          >
            {momentDate.local().format("YYYY-MM-DD HH:mm:ss")}
          </TableCell>
        );
      } catch (err) {
        momentDate = moment(
          orderItem?.orderReadyTime ?? orderItem?.orderTimestamp
        );
        return (
          <TableCell
            style={
              momentDate.isBefore(new Date())
                ? {
                    backgroundColor: "red",
                    color: "white",
                  }
                : {}
            }
          >
            {momentDate.local().format("YYYY-MM-DD HH:mm:ss")}
          </TableCell>
        );
      }
    }
  };

  return (
    <TableContainer component={Paper} sx={{ overflow: "scroll" }}>
      <Table
        stickyHeader
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Order Number</b>
            </TableCell>
            <TableCell>
              <b>Status</b>
            </TableCell>
            {/* <TableCell>
              <b>Time</b>
            </TableCell> */}
            {/* <TableCell>
              <b>Ordered By</b>
            </TableCell> */}
            <TableCell>
              <b>Pickup At</b>
            </TableCell>
            <TableCell>
              <b>Deliver To</b>
            </TableCell>
            <TableCell>
              <b>Delivery Time</b>
            </TableCell>
            <TableCell>
              <b>Driver</b>
            </TableCell>
            {/* <TableCell>
              <b>Status</b>
            </TableCell> */}
            {/* <TableCell>
              <b>Actions</b>
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders && orders?.length > 0 ? (
            orders?.map((row) => (
              <TableRow
                hover
                role="checkbox"
                onClick={(event) => {
                  onEditClick(row);
                }}
                key={row.orderNumber}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell component="th" scope="row">
                  {row?.orderNumber}
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: getBackgroundColor(row?.orderStatus),
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    {getStatusIcon(row?.orderStatus)}
                    {getNewStatusMappings(row?.orderStatus)}
                  </span>
                </TableCell>
                {/* <TableCell>
                  {moment(row?.orderTimestamp).format("YYYY-MM-DD HH:mm:ss")}
                </TableCell> */}
                {/* <TableCell>{row?.ordererID}</TableCell> */}
                <TableCell>
                  <Tooltip title={row?.pickupDetails?.address ?? ""}>
                    <div>{row?.pickupDetails?.city}</div>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={row?.deliveryDetails?.address ?? ""}>
                    <div>{row?.deliveryDetails?.city}</div>
                  </Tooltip>
                </TableCell>
                {/* <TableCell> */}
                {getDeliveryTime(row)}
                {/* {moment(row?.orderTimestamp).format("YYYY-MM-DD HH:mm:ss")} */}
                {/* </TableCell> */}
                <TableCell>
                  {drivers?.find(
                    (e) => e?.driverNumber === parseInt(row?.assignedDriver)
                  )?.driverName ?? "Not Assigned"}
                </TableCell>
                {/* <TableCell>{row?.orderStatus}</TableCell> */}
                {/* <TableCell>
                  <Button
                    onClick={() => {
                      onEditClick(row);
                    }}
                  >
                    Edit
                  </Button>
                </TableCell> */}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No Records to Display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
