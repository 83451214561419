import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog(props) {
  const {
    open,
    handleClose,
    handleConfirm,
    dialogTitle,
    dialogContent,
    customControls,
    maxWidth,
  } = props;
  return (
      <Dialog
        open={open}
        // onClose={handleClose}
        fullWidth
        maxWidth={maxWidth ? maxWidth : undefined}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          {dialogContent}
          {/* </DialogContentText> */}
        </DialogContent>
        {customControls !== false && (
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="error">
              Cancel
            </Button>
            <Button onClick={handleConfirm} autoFocus variant="contained">
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>
  );
}
