import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MuiColorInput } from "mui-color-input";
import { primaryButtonColor } from "constants";
import { primaryButtonHoverColor } from "constants";
import { useForm } from "react-hook-form";
import AlertDialog from "Components/AlertDialog/AlertDialog";
import RenderInput from "Templates/UI/RenderInput";
import { VishcorpContext } from "Context";
import { createDriver } from "API/Drivers";

export default function AddDriver(props) {
  const { orgSettings } = useContext(VishcorpContext);
  const [driverDetails, setDriverDetails] = useState(undefined);
  const [dispatchView, setDispatchView] = useState(true);
  const { handleClose } = props;
  const [confirmOrder, setConfirmOrder] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const [driverSettings, setDriverSettings] = useState(undefined);

  useEffect(() => {
    setDriverSettings(orgSettings?.organizationSettings?.driverSettings);
  }, [orgSettings]);

  const updateDriverDetails = (property, value) => {
    setDriverDetails((preVal) => ({
      ...preVal,
      [property]: value,
    }));
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper style={{ padding: 20, margin: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginLeft: 5 }}>
            <FormLabel style={{ fontSize: "2rem" }}>New Driver</FormLabel>
          </Grid>
        </Grid>
        <br />
        {/* <TextField
          error={errors?.driverNumber && true}
          helperText={errors?.driverNumber?.message}
          {...register("driverNumber", {
            required: "Required*",
          })}
          size="small"
          label="Number"
          variant="outlined"
          value={driverDetails?.driverNumber ?? ""}
          onChange={(e) =>
            updateDriverDetails("driverNumber", e?.target?.value)
          }
          style={{ width: "100%", marginBottom: 15 }}
        /> */}
        <TextField
          error={errors?.driverName && true}
          helperText={errors?.driverName?.message}
          {...register("driverName", {
            required: "Required*",
          })}
          size="small"
          label="Name"
          variant="outlined"
          value={driverDetails?.driverName ?? ""}
          onChange={(e) => updateDriverDetails("driverName", e?.target?.value)}
          style={{ width: "100%", marginBottom: 15 }}
        />
        <TextField
          error={errors?.commission && true}
          helperText={errors?.commission?.message}
          {...register("commission", {
            required: "Required*",
          })}
          size="small"
          label="Commission %"
          variant="outlined"
          value={driverDetails?.commission ?? ""}
          onChange={(e) => {
            const regex = /^(\d)*(\.)?([0-9]{0,2})?$/g;
            // const regex = /^[0-9\b]+$/;
            if (e.target.value === "" || regex.test(e.target.value)) {
              updateDriverDetails("commission", e?.target?.value);
            }
          }}
          style={{ width: "100%", marginBottom: 15 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => {
                setDispatchView((preVal) => !preVal);
              }}
              checked={dispatchView}
            />
          }
          label="Show in this dispatch view"
        />
        <br />
        <br />
        <FormLabel style={{ fontSize: "1.25rem" }}>E-Dispatching</FormLabel>
        <RadioGroup
          value={driverDetails?.dispatchingType ?? "byProvider"}
          row
          onChange={(e) =>
            updateDriverDetails("dispatchingType", e?.target?.value)
          }
        >
          <FormControlLabel
            value="byProvider"
            control={<Radio />}
            label="By Provider (Recommended)"
          />
          <FormControlLabel value="email" control={<Radio />} label="E-Mail" />
        </RadioGroup>
        <TextField
          error={errors?.phone && true}
          helperText={errors?.phone?.message}
          {...register("phone", {
            required: "Required*",
          })}
          size="small"
          label="Phone"
          variant="outlined"
          value={driverDetails?.phone ?? ""}
          onChange={(e) => {
            const onlyNums = e.target.value.replace(/[^0-9]/g, "");
            if (onlyNums.length < 10) {
              updateDriverDetails("phone", onlyNums);
            } else if (onlyNums.length === 10) {
              const number = onlyNums.replace(
                /(\d{3})(\d{3})(\d{4})/,
                "($1) $2-$3"
              );
              updateDriverDetails("phone", number);
            }
          }}
          style={{ width: "100%", marginBottom: 15, marginTop: 15 }}
        />
        <TextField
          error={errors?.email && true}
          helperText={errors?.email?.message}
          {...register("email", {
            required: "Required*",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format",
            },
          })}
          size="small"
          label="Email"
          variant="outlined"
          value={driverDetails?.email ?? ""}
          onChange={(e) => {
            updateDriverDetails("email", e?.target?.value);
          }}
          style={{ width: "100%", marginBottom: 15, marginTop: 15 }}
        />
        {/* <FormControl fullWidth>
          <InputLabel id="provider-label">Provider</InputLabel>
          <Select
            labelId="provider-label"
            label={"Provider"}
            fullWidth
            value={driverDetails?.provider ?? "ACS Wireless"}
          >
            <MenuItem value={"ACS Wireless"}>ACS Wireless</MenuItem>
          </Select>
        </FormControl> */}
        <br />
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormLabel style={{ fontSize: "1.25rem" }}>Deduction</FormLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={errors?.biker && true}
              helperText={errors?.biker?.message}
              // {...register("biker", {
              //   required: "Required*",
              // })}
              size="small"
              label="Biker"
              variant="outlined"
              value={driverDetails?.biker ?? ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  updateDriverDetails("biker", e?.target?.value);
                }
              }}
              style={{ width: "100%", marginBottom: 15, marginTop: 15 }}
            />
            <TextField
              error={errors?.radio && true}
              helperText={errors?.radio?.message}
              // {...register("radio", {
              //   required: "Required*",
              // })}
              size="small"
              label="Radio"
              variant="outlined"
              value={driverDetails?.radio ?? ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  updateDriverDetails("radio", e?.target?.value);
                }
              }}
              style={{ width: "100%", marginBottom: 15 }}
            />
            <TextField
              error={errors?.uniform && true}
              helperText={errors?.uniform?.message}
              // {...register("uniform", {
              //   required: "Required*",
              // })}
              size="small"
              label="Uniform"
              variant="outlined"
              value={driverDetails?.uniform ?? ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  updateDriverDetails("uniform", e?.target?.value);
                }
              }}
              style={{ width: "100%", marginBottom: 15 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={errors?.cashCalls && true}
              helperText={errors?.cashCalls?.message}
              // {...register("cashCalls", {
              //   required: "Required*",
              // })}
              size="small"
              label="Cash Calls"
              variant="outlined"
              value={driverDetails?.cashCalls ?? ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  updateDriverDetails("cashCalls", e?.target?.value);
                }
              }}
              style={{ width: "100%", marginBottom: 15, marginTop: 15 }}
            />
            <TextField
              error={errors?.advances && true}
              helperText={errors?.advances?.message}
              // {...register("advances", {
              //   required: "Required*",
              // })}
              size="small"
              label="Advances"
              variant="outlined"
              value={driverDetails?.advances ?? ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  updateDriverDetails("advances", e?.target?.value);
                }
              }}
              style={{ width: "100%", marginBottom: 15 }}
            />
            <TextField
              error={errors?.others && true}
              helperText={errors?.others?.message}
              // {...register("others", {
              //   required: "Required*",
              // })}
              size="small"
              label="Others"
              variant="outlined"
              value={driverDetails?.others ?? ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  updateDriverDetails("others", e?.target?.value);
                }
              }}
              style={{ width: "100%", marginBottom: 15 }}
            />
            <TextField
              error={errors?.incompleteWaybills && true}
              helperText={errors?.incompleteWaybills?.message}
              // {...register("incompleteWaybills", {
              //   required: "Required*",
              // })}
              size="small"
              label="Incomplete Waybills"
              variant="outlined"
              value={driverDetails?.incompleteWaybills ?? ""}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  updateDriverDetails("incompleteWaybills", e?.target?.value);
                }
              }}
              style={{ width: "100%", marginBottom: 15 }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormLabel style={{ fontSize: "1.25rem" }}>Color</FormLabel>
        </Grid>
        <MuiColorInput
          value={driverDetails?.color ?? "#ffffff"}
          onChange={(color) => {
            updateDriverDetails("color", color);
          }}
          fullWidth
        />

        {driverSettings &&
          driverSettings
            ?.filter((e) => !e?.isDefault && e?.visible)
            ?.map((e, i) => (
              <RenderInput
                value={driverSettings?.[e?.id]}
                setValue={(val) => {
                  updateDriverDetails(e?.id, val);
                }}
                register={register}
                errors={errors}
                type={e?.type}
                required={e?.required}
                label={e?.name}
                id={e?.id}
                key={e?.id}
                control={control}
                options={e?.options ?? []}
                defaultValue={e?.defaultValue}
              />
            ))}
        <br />
        <br />
        <Button
          sx={{
            borderRadius: 0,
            backgroundColor: primaryButtonColor,
            "&:hover": {
              backgroundColor: primaryButtonHoverColor,
            },
          }}
          variant="contained"
          onClick={handleSubmit(() => {
            setConfirmOrder(true);
            console.log("submitting driver details", driverDetails);
          })}
        >
          Add Driver
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: 10 }}
          sx={{
            borderRadius: 0,
          }}
          color="error"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
      </Paper>
      <AlertDialog
        open={confirmOrder}
        handleClose={() => {
          setConfirmOrder(false);
        }}
        handleConfirm={async () => {
          //   const userInfo = await Auth.currentUserInfo();
          await createDriver(
            driverDetails,
            orgSettings?.organizationID,
            orgSettings?.organizationTablePrefix
          ).then((res) => {
            setConfirmOrder(false);
            handleClose();
            // console.log("response here", res);
          });
          //   resetForm();
        }}
        dialogTitle="Confirm Add Driver"
      />
    </Box>
  );
}
