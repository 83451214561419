export const addZoneSet = async (
  zoneSetName,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "ADD_ZONE_SET",
        zoneSetName,
        organizationTablePrefix,
        organizationID: organizationID,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const getZoneSets = async (organizationID, organizationTablePrefix) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ZONE_SETS",
        organizationID: organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.response?.Items)
    .catch(console.error);
};

export const updateZoneSetZone = async (
  zoneSet,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_ZONE_SET_ZONE",
        zoneSetName: zoneSet?.zoneSetName,
        zones: zoneSet?.zones,
        organizationID: organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .catch(console.error);
};

export const addPricingList = async (
  pricingList,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "CREATE_PRICING_LIST",
        pricingListName: pricingList?.pricingListName,
        pricingListType: pricingList?.pricingListType,
        pricingListDetails: { zoneSetName: pricingList?.selectedZoneSet },
        organizationID: organizationID,
        organizationTablePrefix,
      }),
    }
  );
};

export const removePricingList = async (
  pricingListName,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "REMOVE_PRICING_LIST",
        pricingListName: pricingListName,
        organizationID: organizationID,
        organizationTablePrefix,
      }),
    }
  );
};

export const getPricingLists = async (
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_PRICING_LIST",
        organizationID: organizationID,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.response?.Items)
    .catch(console.error);
};

export const updatePricingList = async (
  pricingListName,
  pricingListDetails,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "UPDATE_PRICING_LIST",
        organizationID: organizationID,
        pricingListName: pricingListName,
        pricingListDetails,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.response?.Items)
    .catch(console.error);
};

export const getZoneSet = async (
  zoneSetName,
  organizationID,
  organizationTablePrefix
) => {
  return await fetch(
    "https://rsc0umeq52.execute-api.ap-southeast-2.amazonaws.com/Prod/organization-settings",
    {
      method: "POST",
      body: JSON.stringify({
        action: "GET_ZONE_SET",
        organizationID: organizationID,
        zoneSetName: zoneSetName,
        organizationTablePrefix,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => res?.response)
    .catch(console.error);
};
